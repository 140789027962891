import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperadoraCompetenciaParams } from '@core/types';
import { environment } from 'environments/environment';
import { Api } from '../api';
import {
  ListagemGuia,
  ListagemGuiaParams,
  PAGE_PARAMS_DEFAULT,
  PageParams,
  RankingCbosConsultasEletivas,
  Response,
  RetornoGuia,
  RetornoItem,
  RetornoListagem,
} from './model';

/**
 *  GO API
 */
@Injectable({ providedIn: 'root' })
export class GoApi extends Api {
  constructor(http: HttpClient) {
    super(`${environment.API_GO_BASE_PATH}/simulador`, http);
  }

  fetchRankingCbosConsultasEletivas({
    regans,
    ...params
  }: OperadoraCompetenciaParams & {
    ano_realizacao: number;
    id_faixa_etaria_consulta: number;
    nr_cnpj_cpf_exec?: string;
    tp_rede?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RankingCbosConsultasEletivas>>(
      `/analises/ranking-cbo-consultas/operadora/${regans}`,
      trimmed,
    );
  }

  fetchRetornosGuias({ regans, ...params }: OperadoraCompetenciaParams & { tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoGuia>>(`/guias/retornos-ans/guias/operadora/${regans}`, trimmed);
  }

  fetchRetornosItens({ regans, ...params }: OperadoraCompetenciaParams & { tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoItem>>(`/guias/retornos-ans/itens/operadora/${regans}`, trimmed);
  }

  fetchRetornoListagem({
    regans,
    ...params
  }: OperadoraCompetenciaParams & { nr_campo: number; cd_motivo_glosa: number; tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoListagem>>(`/guias/retornos-ans/listagem/operadora/${regans}`, trimmed);
  }

  fetchListagemGuias({ regans, ...params }: ListagemGuiaParams, page: PageParams = PAGE_PARAMS_DEFAULT) {
    const trimmed = this.trimParams(params);
    return this.get<Response<ListagemGuia>>(`/guias/listagem-guias/operadora/${regans}`, {
      ...trimmed,
      ...page,
    });
  }
}
