<div class="pagination flex align-items-center">
  <button pButton class="p-button-outlined mr-2" [disabled]="page == 0" (click)="goToPage(page - 1)">Anterior</button>

  <button
    *ngIf="minPage > 0"
    pButton
    class="p-button-outlined w-3rem min-w-min mr-2 justify-content-center"
    [disabled]="page == 0"
    (click)="goToPage(0)"
  >
    1
  </button>

  <span class="mr-2 font-medium" *ngIf="minPage > 1"> ... </span>

  <button
    pButton
    *ngFor="let option of pageNavOptions"
    class="p-button-outlined w-3rem min-w-min mr-2 justify-content-center"
    [ngClass]="{ active: page == option }"
    [disabled]="page == option"
    (click)="goToPage(option)"
  >
    {{ option + 1 }}
  </button>

  <span class="mr-2 font-medium" *ngIf="lastOffsetFound && maxPage < lastPage - 1"> ... </span>

  <button
    *ngIf="lastOffsetFound && maxPage < lastPage"
    pButton
    class="p-button-outlined w-3rem min-w-min mr-2 justify-content-center"
    [disabled]="page == lastPage"
    (click)="goToPage(lastPage)"
  >
    {{ lastPage + 1 }}
  </button>

  <button pButton class="p-button-outlined mr-2" [disabled]="page >= maxPage" (click)="goToPage(page + 1)">
    Próximo
  </button>

  <span class="p-float-label p-input-icon-right">
    <p-dropdown
      class="p-inputtext-sm"
      [options]="sizeOptions"
      [(ngModel)]="size"
      (onChange)="onSizeChanged()"
    ></p-dropdown>
    <label class="text-sm" for="filterGuiaOperadora">Exibir</label>
  </span>
</div>
