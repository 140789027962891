<div
  *ngIf="loading$ | async"
  style="position: absolute; top: 0; right: 0; left: 0; z-index: 9999; background-color: #fff"
>
  <p-progressBar
    data-smartpresentation="barra-carregamento"
    mode="indeterminate"
    [style]="{ height: '5px' }"
  ></p-progressBar>
</div>
<div
  class="layout-wrapper"
  [ngClass]="{
    'p-input-filled': layoutConfig.inputStyle === 'filled',
    'p-ripple-disabled': !layoutConfig.ripple,
    'layout-menu-static': isStatic(),
    'layout-menu-overlay': isOverlay(),
    'layout-menu-horizontal': isHorizontal(),
    'layout-menu-slim': isSlim(),
    'layout-menu-active': menuActive && (menuVisible$ | async),
    'layout-menu-mobile-active': mobileMenuActive,
    'layout-topbar-mobile-active': mobileTopbarActive,
  }"
  [class]="'layout-menu-' + layoutConfig.menuTheme + ' layout-topbar-' + layoutConfig.topbarTheme"
>
  <app-topbar *ngIf="!(fullscreen$ | async)"></app-topbar>

  <div class="menu-wrapper shadow-none" (click)="onMenuClick($event)" *ngIf="menuVisible$ | async">
    <div class="layout-menu-container">
      <app-menu></app-menu>
      <!-- <app-inline-menu key="bottom"></app-inline-menu> -->
    </div>
  </div>

  <div class="layout-main" [ngClass]="{ 'layout-fullscreen': (fullscreen$ | async) }">
    <!-- <app-breadcrumb *ngIf="!(fullscreen$ | async)"></app-breadcrumb> -->

    <div class="layout-content flex flex-column mt-2">
      <router-outlet></router-outlet>
    </div>

    <p-toast></p-toast>
    <app-footer *ngIf="!(fullscreen$ | async)"></app-footer>
  </div>

  <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>
