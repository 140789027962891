import { AbstractControl, Validators } from '@angular/forms';
import { UsuarioPerfil } from '@core/api/ords/model';

/**
 * Comparada dos objetos pelo ano base
 *
 * @param a
 * @param b
 * @returns
 */
export const anoBaseComparator = (a: any, b: any) => a.nr_ano_base - b.nr_ano_base;

export const sortyByAnoBase = (items: any[]) => [...items].sort(anoBaseComparator);

export function validateCPF(cpf: string, allowEmpty: boolean = false): boolean {
  if (!cpf) {
    return allowEmpty;
  }

  let digits = cpf;

  // remove mask
  if (cpf.match(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)) {
    digits = cpf.replace(/[.-]/g, '');
  }

  // if not 11 digits or 11 same digits
  if (!digits.match(/^\d{11}$/) || digits.match(/^(\d)\1{10}$/)) {
    return false;
  }

  const dv1 = +digits.charAt(9);
  const dv2 = +digits.charAt(10);

  let sum = 0;
  for (let i = 10; i > 1; i--) {
    sum += +digits.charAt(10 - i) * i;
  }

  const r1 = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (r1 != dv1) {
    return false;
  }

  sum = 0;
  for (let i = 11; i > 1; i--) {
    sum += +digits.charAt(11 - i) * i;
  }

  const r2 = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (r2 != dv2) {
    return false;
  }

  return true;
}

export function makeCompetenciaRangeFromAno(ano: number): Date[] {
  const date = new Date().setFullYear(ano);
  const dateStart = new Date(date);
  dateStart.setMonth(0);
  const dateEnd = new Date(date);
  dateEnd.setMonth(11);
  return [dateStart, dateEnd];
}

export function convertCompetenciaRangeToString(range: Date[]): string[] {
  const start: Date = range[0];
  const end: Date = range[1];
  return [
    `${start.getFullYear()}${start.getMonth() < 9 ? 0 : ''}${start.getMonth() + 1}`,
    `${end.getFullYear()}${end.getMonth() < 9 ? 0 : ''}${end.getMonth() + 1}`,
  ];
}

export class CustomValidators {
  private constructor() {}

  static validateCPF(control: AbstractControl): Validators {
    const value = control.value;
    if (validateCPF(value)) {
      return null;
    }
    return {
      invalidCPF: true,
    };
  }
}

export function normalizeOperadora(regans?: string | number): string {
  let normalizado = regans ? regans + '' : null;
  if (normalizado && normalizado.length < 6) {
    normalizado = '0'.repeat(6 - normalizado.length) + normalizado;
  }
  return normalizado;
}

export function normalizeOperadoras<T extends { cd_registro_ans: string | number }>(items: T[]): T[] {
  return items.map(op => {
    const cd_registro_ans = normalizeOperadora(op.cd_registro_ans);
    return {
      ...op,
      cd_registro_ans,
    };
  });
}

export function normalizePerfil(perfil: UsuarioPerfil) {
  let cd_registro_ans_preferencial = perfil.cd_registro_ans_preferencial
    ? perfil.cd_registro_ans_preferencial + ''
    : null;
  if (cd_registro_ans_preferencial && cd_registro_ans_preferencial.length < 6) {
    cd_registro_ans_preferencial = '0'.repeat(6 - cd_registro_ans_preferencial.length) + cd_registro_ans_preferencial;
  }
  if (perfil.operadoras_vinculadas) {
    perfil.operadoras_vinculadas = normalizeOperadoras(perfil.operadoras_vinculadas);
  }
  return {
    ...perfil,
    cd_registro_ans_preferencial,
  };
}

export function compareDates(date1: string, date2: string) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  let difference = d1.getTime() - d2.getTime();
  return difference;
}

export function pseudonimizar(value: string | number, max = 3, mask = '***'): string {
  if (value == null) {
    return null;
  }

  let str = '' + value;

  if (str.length <= max) {
    return mask;
  }

  return str.substring(0, max) + mask;
}

export function pseudonimizarPrimeiroNome(value: string): string {
  if (value == null) {
    return null;
  }

  const parts = value.trim().split(/\s+/);

  return parts.length ? parts[0] : '';
}

export function numberOnlyKeyEvent(event): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function filterToQueryString(filter: Record<string, string | number | boolean>): string {
  const queryParams = Object.entries(filter)
    .filter(([k, v]) => v != null)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  if (!queryParams) {
    return window.location.href;
  }

  let queryAppend = '?';
  if (window.location.search) {
    queryAppend = '&';
  }

  return `${window.location.href}${queryAppend}${queryParams}`;
}
